@import "@/assets/scss/style.scss";

.wrapper {
    padding: 24px;
    width: 50%;
    margin: 16px auto;
    background-color: $white-two;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    h2 {
        margin-bottom: 24px;
    }
}

.button {
    height: 46px;
    margin-bottom: 16px;
    display: block;
    width: 50%;
    min-width: fit-content;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.disabled-button {
    background-color: #555555;
    opacity: 0.5;
    pointer-events: none;
}

@media (max-width: 1200px) {
    .wrapper {
        width: 70%;
    }
}

@media (max-width: 768px) {
    .wrapper {
        width: 80%;
    }

    .button {
        width: 70%;
    }
}
